<template>
    <div class="wraper" v-if="detail">
        <Header :isShow="isShow" />
        <div class="banner">
            <img src="@/assets/images/gz_banner.jpg" alt="" />
        </div>
        <div class="content max_small_box" ref="header" >
             <div class="title max_small_box">
                <p >{{detail.name}}</p>
            </div>
            <div class="line"></div>
            <div class="detail max_small_box"  v-html="detail.intro">
            </div>
            <!-- <div class="line"></div> -->
        </div>
       
    </div>
</template>

<script>
import { onUnmounted, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { get, post } from '../../utils/request';
import * as api from '../../utils/api';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FloatingFrame from '../../components/floatingFrame/FloatingFrame';

//页面滚动处理函数
const ScrollEffect = () => {

    const isShow = ref()

    const handleScroll = (height) => {
        
        if(document.documentElement.scrollTop >= height){
            isShow.value = 1
        }else{
            isShow.value = 2
        }
    }

    return { handleScroll, isShow }
}

//详情处理模块
const detailEffect = () => {

    //详情
    const detail = ref()

     //获取详情
    const getDetail = async () => {
        let postData = {
            page_id: '/',
            name: '公正性承诺'
        }
        try{
            const result = await post('/index/get_statement', postData)
            // console.log('公正性声明详情', result)
            if(result.code == 1){
                detail.value = result.data
            }else{
                // console.log('请求失败')
            }
        }catch(e) {
        
        }
    } 

    return { detail, getDetail }
}

export default {
    name: 'Statement',
    components: { Header, Footer, FloatingFrame },
    setup() {
        const route = useRoute()
        //页面滚动处理函数
        const { 
            handleScroll, 
            isShow 
        } = ScrollEffect()

        //详情处理模块
        const { detail, getDetail } = detailEffect()

        //获取滑块到顶部的距离
        const header = ref(null)

        onMounted(() => {
            document.documentElement.scrollTop = document.body.scrollTop =0;
            window.addEventListener('scroll', function () {
                if(header.value){
                    handleScroll(header.value.offsetTop);
                }
            })
        });

        onUnmounted(() => {
            document.querySelector('body').setAttribute('style', 'background-color:#fff')
        })

        
        getDetail()  //获取详情

        const { imgUrl }  = api

        return { imgUrl, isShow, header, detail }
    }
}
</script>

<style lang="scss" scoped>
@import '../../style/viriables.scss';
/* 轮播图 */
.banner{
    margin-top: 111px;
    @media screen and (max-width: 1200px){
        margin-top: $phone-banner-marginTop;
        height: $phone-banner-height;
    }
    img{
        width: 100%;
        display: block;
        @media screen and (max-width: 1200px){
            height: 100%;
            object-fit: cover;
        }
    }
}
.content{
    margin-top: 50px;
    .title{
        text-align: center;
        color: rgb(16, 16, 16);
        font-size: 20px;
        font-weight: 700;
        @media screen and (max-width: 1200px){
            padding: 0 30px;
        }
        p{
            color: rgba(16, 16, 16, 100);
            font-size: 20px;
            font-weight: bold;
            @media screen and (max-width: 900px){
                font-size: .2rem;
            }
        }
    }
    .line{
        width: 100%;
        height: 1px;
        background: rgba(187, 187, 187, 100);
        margin-top: 30px;
    }
    .detail{
        padding: 30px;
    }
}

</style>